<script lang="ts">
	import Footer from "src/components/footer.svelte";
	import Header from "src/components/header.svelte";
	import ToolBrowser from "src/components/methodology/tool-browser/tool-browser.svelte";
</script>

<div class="default-content-width">
	<Header />

	<ToolBrowser />

	<footer>
		<Footer />
	</footer>
</div>
